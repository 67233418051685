<template>
    <div class="walrus">
        <h1>Créer un flux RSS</h1>
        <div>
            <curebot-input 
                :loading="frameLoading"
                @keyup.enter="loadPage" 
                placeholder="Url de la page"
                icon="search"
                :button="inputButtonIcon"
                @buttonClick="loadPage"
                v-model="fieldUrl"  />
        </div>

        <h1>Comment ça fonctionne ?</h1>
        <div class="howto">
            <p>
                Survolez et <kbd>Clic</kbd> sur un article à inclure
                dans le flux RSS.
            </p>
            <p>
                Tous les liens similaires sont automatiquement sélectionnés
                dans la page web.
            </p>
        </div>

        <div class="help">
            <h2>Options supplémentaires</h2>
            <p>
                <kbd>Ctrl + clic</kbd> : 
                Ajouter des liens à la sélection (Cmd + clic sur Mac)
            </p>
            <p>
                <kbd>Alt + clic</kbd> ou <kbd>click droit</kbd> :
                Masquer un élément (RGPD, popup...)
            </p>
            <p>
                <kbd>Shift + clic</kbd> : 
                Naviguer dans la page
            </p>
        </div>

        <div class="mode" role="button" @click="toggleAdvancedMode">
            <icon name="caret-down" v-if="advancedMode" />
            <icon name="caret-right" v-else />
            Parametrage avancé
        </div>
        <div class="additional-fields" v-show="advancedMode">
            <div>
                <h2>Personnaliser le titre du flux</h2>
                <input v-model="customName" />
            </div>
            <div>
                <h2>Modifier manuellement mon selecteur</h2>
                <div class="css-help">
                    Vous pouvez modifier manuellement le 
                    <a href="https://www.w3schools.com/cssref/css_selectors.asp" 
                       target="_blank">
                        sélecteurs CSS
                    </a>. Spécifiez plusieurs 
                    sélecteurs séparés par des virgules, 
                    par exemple : .desc h1 a, .news .detail
                </div>
                <input v-model="selectorInput" />
            </div>
         </div>


        <template v-if="displayResult">
            <h1>Flux RSS</h1>
            
            <div class="info">


                <div class="rssLink">
                    <a class="rssLink" 
                       :href="walrusFeedUrl" 
                       :title="walrusFeedUrl"
                       target="_blank">
                        {{walrusFeedUrl}}
                    </a>
                    <span role="button" class="copy"
                        title="Copier l'url"
                        @click="copyFeedUrlToClipboard" >
                        <icon name="copy" />
                    </span>
                    <span role="button" class="refresh"
                        title="Rafraichir le flux"
                        @click="refreshLinks" >
                        <icon name="undo" />
                    </span>
                </div>
                <div class="add-to" v-if="displayCurebotButton" >
                    <curebot-button 
                        @click.prevent="backToCurebot"
                        icon="plus" >
                        <span v-html='addToButton' />
                    </curebot-button>
                </div>
            </div>
        
            <div v-if="loading" class="loading"> 
                <icon name="circle-notch" spin />
                Chargement ...
            </div>
            <div v-else class="entries">
            <h2>{{foundLinks}} Liens correspondants</h2>
                <pre class="error" v-if="error">{{error}} : {{JSON.stringify(error,null,2)}}</pre>
                <template v-else>
                    <h4>{{links.feed_name}}</h4>
                    <ul class="links" v-if="links.found_links && links.found_links.length >0" >
                        <li 
                            class="entry" 
                            v-for="link in links.found_links" :key="link.url">
                            <!-- <div class="title">{{link.label}}</div> -->
                            <a target="_blank" :href="link.url">{{link.url}}</a>
                        </li>
                    </ul>
                    <div class="no-links" v-else>
                        Aucun lien trouvé
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>
<script>
import config from '@/application-config'
import CurebotButton from '@/CurebotButton'
import CurebotInput from '@/CurebotInput'
import copy from 'copy-to-clipboard'
import Icon from 'vue-awesome/components/Icon' 
export default {
    components:{CurebotButton, CurebotInput,Icon},
    props:{
        url:{
            required:true,
            type:String
        },
        selector:{
            required:true,
            type:String
        },
        frameLoading:{
            default:false,
            type:Boolean
        },
        frameError:{
            default:null,
            type:Object
        }
    },
    data() {
        return {
            links : {},
            error:null,
            loading : false,
            selectorInput:"",
            customName:"",
            fieldUrl:"",
            loadedUrl:null,
            advancedMode:false
        }
    },
    computed:{
        inputButtonIcon(){
            if(this.displayValidateButton){
                return 'search'
            }else{
                return 'undo'
            }
        },
        addToButton(){
            if('catalog' in this.$route.query) {
                return "Ajouter ce flux RSS<br/>au Catalogue"
            } else {
                return "Ajouter ce flux RSS<br/> au Bot"
            }
        },
        walrusFeedUrl() {
            return this.walrusUrl()
        },
        walrusJsonUrl(){
            return this.walrusUrl('json')
        },
        foundLinks(){
            if(this.links.found_links){
                return this.links.found_links.length
            }
            return 0
        },
        displayCurebotButton(){
            return true ||this.haveOpener() && this.foundLinks>0
        },
        displayValidateButton(){
            if(this.loadedUrl === null) return true
            if(this.loadedUrl !== this.fieldUrl) return true
            return false
        },
        displayResult(){
            return (
                !this.frameError && 
                ! this.frameLoading && 
                this.loadedUrl && 
                this.selectorInput.trim().length>0
            )
        }
    },
    watch: {
        selector: {
            immediate:true,
            handler(){
                this.selectorInput = this.selector
            }
        },
        selectorInput: {
            immediate:true,
            handler(value){
                this.$emit('selector-changed',value)
            }
        },
        walrusFeedUrl : {
            immediate:true,
            handler(){
                this.refreshLinks()
            }
        }
    },
    methods : {
        toggleAdvancedMode(){
            this.advancedMode = !this.advancedMode
        },
        urlFromRoute(){
            if('url' in this.$route.query){
                return this.$route.query.url
            }
            return ''
        },
        loadPage(){
            if(this.displayValidateButton){
                console.log('loadPage:', this.fieldUrl)
                if(this.fieldUrl.trim().startsWith('http://') || 
                    this.fieldUrl.trim().startsWith('https://')
                ){
                    this.loadedUrl = this.fieldUrl.trim()
                }else{
                    this.loadedUrl = 'https://'+this.fieldUrl.trim()
                }
                this.fieldUrl = this.loadedUrl
                this.$emit('load-page', this.fieldUrl)
            } else {
                this.reloadPage()
            }
        },
        reloadPage(){
            console.log('reloadPage:')
            this.$emit('reload-page')
            this.refreshLinks()
        },
        haveOpener(){
            return window.opener !== null
        },
        backToCurebot(){
            if(window.opener && window.opener.postMessage){
                window.opener.postMessage({
                    walrus:{
                        rss_source : {
                            url:this.walrusFeedUrl,
                            name:this.links.feed_name
                        }
                    }
                },'*')
                window.opener.focus()
                window.close()
            }
        },
        walrusUrl(format=null){
             var url = new URL(
                config.walrus_api_base+"/walrus-links/"+
                encodeURIComponent(this.loadedUrl)
            )
            if(this.selectorInput) {
                url.searchParams.append("selector", this.selectorInput)
            }
            if(this.customName) {
                url.searchParams.append("name", this.customName)
            }
            if(format){
                url.searchParams.append("format", format)
            }
            return url.toString()
        },
        async refreshLinks(){
            this.error = null
            this.links = {}
            if(this.loadedUrl){
                this.loading = true
                try {
                    this.links = await fetch(
                        this.walrusJsonUrl
                    ).then(
                        response => response.json()
                    )
                }catch(e){
                    console.error("oups", e)
                    this.error = e
                }finally{
                    this.loading = false
                }
            }
        },
        copyFeedUrlToClipboard(){
            copy(this.walrusFeedUrl)
        }
    },
    mounted(){
        const url = this.urlFromRoute()
        if (url){
            this.fieldUrl = url
            this.loadPage()
        }
    },
    created(){
        this.$on('set-url-to-load', url => {
            this.fieldUrl = url
            this.loadPage()
        })
    }
}
</script>
<style lang="scss" scoped>
    @import '@/scss/vars';

    .walrus{
        @extend %text-medium;
        padding:10px;
        margin: 0 10px;
        overflow-y:hidden;
        display:flex;
        flex-direction: column;
    }

    .help{
        margin-bottom : 10px;
    }

    .links{
        margin:0px;
        padding:0px;
    }
    .entries{
        overflow-y:auto;
        min-height:300px;
    }

    .entry{
        list-style-type: none;
        margin:0px;
        padding:10px;
        margin-bottom:10px;
        border:solid 1px $blue;
        overflow:hidden;
        font-size: 10pt;
        .title{
            font-weight:bold;
            color:$dark-grey;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        a {
            color:$blue;
        }
    }
    .error{
        color : $red;
        border:solid 1px $red;
        margin:10px;
        padding:10px;
    }
    .info {
        margin:10px 0;
    }
    .no-links, .loading {
        font-size:10pt;
        font-style: italic;
    }
    .additional-fields{
        label {
            display:block;
            margin-top:$padding;
        }
        input {
            width:100%;
        }
    }
    .rssLink{
        display:flex;
        flex-direction: line;

        span {
            flex: 0 0 auto;
        }
        a {
            flex: 1 1 auto;
            color:$blue;
            font-size: 9pt;
            white-space: nowrap;
            overflow: hidden;
            display: block;
            text-overflow: ellipsis;
        }
        .copy, .refresh{
            .fa-icon{
                color:$blue;
            }
            padding-left:$padding;
            cursor: pointer;
        }
    }
    .mode{
        text-align: left;
        margin-right:$padding;
        cursor:pointer;
    }
    .howto{

    }
    .add-to{
        text-align:center;
        padding-top:2*$padding;
    }

    kbd{
        background-color: $light-grey;
        padding:2px $padding;
    }
    p {
        margin-top:0;
        margin-bottom:$padding;
    }
</style>